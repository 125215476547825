<template>
  <v-divider dark></v-divider>
</template>
<script>

export default {
  name: 'OrganizzeDivider',
  props: {},
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-divider {
    background: $colorPrimary;
  }
</style>